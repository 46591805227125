import { ManualTitle } from '../../component/ManualTitle';
import ManualPageList from '../../component/ManualPageList';
import ManualCategoryMaintenancePlan from '../../component/ManualCategoryMaintenancePlan';
import ManualModalBill from '../../component/ManualModalbill';

export const ManualMaintenancePlan002: React.FC = () => {
  return (
    <>
      <ManualTitle
        id="manual-002"
        SubTitle={['どこでもメンテナンス', <br key="key" />, 'プラン']}
      ></ManualTitle>
      <ManualCategoryMaintenancePlan id="manual-002"></ManualCategoryMaintenancePlan>
      <section className="section-common">
        <div className="container has-background-white">
          <h3
            className="common-category-header has-text-weight-bold has-text-centered"
            id="about"
          >
            どこでもメンテナンスプラン
            <br />
            について
          </h3>
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            どこでもメンテナンスプランでできること
          </h4>
          <div className="inner">
            <h5 className="common-text-header has-text-weight-bold">
              車検などのメンテナンス
            </h5>
            <p className="is-margin-top-7">
              お客様のお好きな整備業者にて点検実施後、お支払いいただいた車検代や法定12ヶ月点検料の他、お車のメンテナンス時に発生した費用を、上限回数・金額内でキャッシュバックいたします。
            </p>
            <h5 className="common-text-header has-text-weight-bold has-text-border">
              <p>無償譲渡</p>
            </h5>
            <p className="is-margin-top-7">
              ご契約満了後にお車の無償譲渡をお選びいただけます。
            </p>
            <h5 className="common-text-header has-text-weight-bold has-text-border">
              <p>
                どこでもメンテナンスプランからかんたんメンテナンスプランへの切替について
              </p>
            </h5>
            <p className="is-margin-top-7">
              車検満了日の60日～30日前であれば切替が可能です。
            </p>
            <a
              href="https://carmo-kun.jp/docs/maintenance/used/dokodemo_maintenance_v1.pdf"
              className="is-margin-top-7 button-base-arrow has-text-weight-bold has-text-black button-orange"
            >
              メンテナンスプラン<br />サービス資料はこちら
            </a>
            <p className="common-back-category has-text-right is-margin-top-7 has-text-border">
              <a href="#category" className="has-text-black">
                カテゴリ選択に戻る
              </a>
            </p>
          </div>
          <h3
            className="common-category-header has-text-weight-bold has-text-centered"
            id="invoice"
          >
            メンテナンス請求について
          </h3>
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            ご請求の流れ
          </h4>
          <div className="inner">
            <ul className="common-step is-margin-top-4">
              <li className="is-flex is-mobile">
                <p className="common-step-flow">
                  点検・消耗品
                  <br />
                  交換実施
                </p>
                <div className="common-step-text">
                  お客様のご都合の良い整備業者にてご予約のうえ、点検や消耗品の交換をお受けください。
                </div>
              </li>
              <li className="is-flex is-mobile">
                <p className="common-step-flow">
                  明細書の
                  <br />
                  お受け取り
                </p>
                <div className="common-step-text">
                  <p>
                    整備業者にて下記を満たした明細書(納品請求書やレシート等)をお受取りください。
                  </p>
                  <ul className="has-text-weight-bold">
                    <li>・点検実施日・整備工場名</li>
                    <li>・ご請求対象項目と項目ごとの料金</li>
                  </ul>
                  <p>
                    ※上記が手書きの場合は社判入り領収証もご用意ください。
                  </p>
                  <p className="is-margin-top-7">
                    請求対象項目をご確認のうえ、点検実施30日以内にご請求ください。
                  </p>
                </div>
              </li>
              <li className="is-flex is-mobile">
                <p className="common-step-flow">明細書の撮影</p>
                <div className="common-step-text">
                  <p>
                    明細書全体が映り(明細書が複数枚にわたる場合は1枚ずつ)
                  </p>
                  <ul className="has-text-weight-bold">
                    <li>・点検実施日</li>
                    <li>・整備工場名</li>
                    <li>・ご請求対象項目と項目ごとの料金</li>
                  </ul>
                  <p>
                    が読み取れるようにご撮影ください。内容が鮮明に読み取れるよう、手振れ等にご注意ください。
                  </p>
                  <ManualModalBill></ManualModalBill>
                </div>
              </li>
              <li className="is-flex is-mobile">
                <p className="common-step-flow">ご請求</p>
                <div className="common-step-text">
                  <p>
                    撮影した画像を添付し、ご請求フォーマットをご入力のうえ、
                    メールにてご請求いただきます
                  </p>
                </div>
              </li>
              <li className="is-flex is-mobile">
                <p className="common-step-flow">内容確認</p>
                <div className="common-step-text">
                  <p>
                    定額カルモくんサポートデスクにて、請求内容を確認いたします。
                  </p>
                  <p className="is-margin-top-4">
                    ※明細書画像の不備や、ご請求項目漏れなどがあった場合、再申請をお願いすることがございます。再申請内容を確認のうえ受領となりますので予めご了承ください。
                  </p>
                </div>
              </li>
              <li className="is-flex is-mobile">
                <p className="common-step-flow">お振込み</p>
                <div className="common-step-text">
                  <p className="has-text-weight-bold">
                    締め日は毎週土曜日・月曜日の週2回です。請求内容に不備がなければ、締め日から4営業日後にお振り込みいたします。
                  </p>
                  <p className="is-margin-top-4">
                    ※再申請ご依頼中や、
                    <span className="has-text-weight-bold">
                      カーライフサポート料のお支払い確認が取れていない場合、確認が取れてからのお振り込みとなります
                    </span>
                    ので予めご了承ください。
                  </p>
                </div>
              </li>
            </ul>
            <a
              href={process.env.REACT_APP_MAINT_REQUEST_URL}
              className="button-base-arrow has-text-weight-bold has-text-black is-margin-top-7"
            >
              メンテナンス請求をする
            </a>
            <p className="is-size-5 has-text-centered">
              ※会員様専用サイトへのログインが必要となります。
            </p>
          </div>
          <h4 className="common-section-header has-text-centered has-text-weight-bold is-margin-top-7">
            ご注意事項
          </h4>
          <div className="inner">
            <h5 className="common-text-header has-text-weight-bold">
              エンジンオイルのボトルキープについて
            </h5>
            <p className="is-margin-top-7">
              エンジンオイルのボトル購入（ボトルキープ）の場合でも、そのボトル代金の上限金額・回数内で適用させていただきます。上限金額や回数の超過分につきましてはお客様負担となります。
              <br />
              ボトル代金が上限金額を大きく超過することもございますため、できればボトル購入ではなく、都度エンジンオイルを交換いただくことをおすすめいたします。
            </p>
            <h5 className="common-text-header has-text-weight-bold has-text-border">
              <p>整備業者のメンテナンスパッケージサービスについて</p>
            </h5>
            <p className="is-margin-top-7">
              ディーラーや整備業者で提供しているメンテナンスのパッケージサービスは、発行される明細書に「実施項目・項目ごとの金額」の記載がないことが多くございます。
            </p>
            <p className="is-margin-top-7">
              上記の場合、確認にお時間を頂戴してしまう、または受領できない場合がございますため、パッケージサービスの加入をお控えいただくか、加入前に条件を満たす明細書の発行が可能か整備業者にご確認いただきますようお願いいたします。
            </p>
            <h5 className="common-text-header has-text-weight-bold has-text-border">
              <p>どこでもメンテナンスプランの上限金額について</p>
            </h5>
            <p className="is-margin-top-7">
              整備業者によっては上限金額を超過する場合がございます。
            </p>
            <p className="is-margin-top-7">
              超過した分はお客様ご負担となりますため、相見積もりなどをお取りいただき、ご負担の少ない整備業者にて行なっていただくことをおすすめいたします。
            </p>
          </div>
        </div>
      </section>
      <ManualPageList id="manual-002"></ManualPageList>
    </>
  );
};
